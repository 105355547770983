@import "../../styles/root.scss";

.container {
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	padding: 60px;
	justify-content: space-between;
	gap: 60px;
	border: 1px solid $primary;
	border-radius: 40px;
}

.wrap {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 30px;
}

.buttonMobile {
	display: none;
}

.buttonMobileRu {
	display: none;
}

.imgMobile {
	display: none;
}

.title {
	font-family: "Manrope";
	font-weight: 800;
	font-size: 36px;
	line-height: 60px;
}

.titleColor {
	color: $primary;
}

.desc {
	max-width: 340px;
	max-height: 108px;
	overflow: hidden;
	font-weight: 500;
	font-size: 17px;
	line-height: 35px;
	color: $primary;
}

.wrapText {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.text {
	font-size: 15px;
	line-height: 34px;
	color: #5e6166;
}


@media only screen and (min-width: 820px) and (max-width: 1100px) {
	.container {
		margin-bottom: 48px;
	}

	.title {
		font-weight: 800;
		font-size: 28px;
		line-height: 32px;
	}

	.desc {
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
	}

	.text {
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
	}
}

@media only screen and (min-width: 600px) and (max-width: 820px) {

	.container {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		padding: 40px;
		justify-content: space-between;
		gap: 30px;
		border: 1px solid $primary;
		border-radius: 40px;
	}

	.wrap {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}

	.wrapText {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.img {
		width: 208px;
		height: 192px;
	}

	.title {
		font-weight: 800;
		font-size: 28px;
		line-height: 32px;
	}

	.desc {
		font-weight: 500;
		font-size: 15px;
		line-height: 24px;
	}

	.text {
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
	}


}


@media only screen and (min-width: 360px) and (max-width: 600px) {
	.container {
		display: flex;
		flex-direction: column;
		padding: 16px;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		border: 1px solid $primary;
		border-radius: 10px;
		// margin-bottom: 48px;
	}

	.button {
		display: none;
	}

	.buttonMobile {
		display: flex;
		flex-direction: row;
		gap: 30px;
	}

	.buttonMobileRu {

		display: flex;
		flex-direction: column;
		align-items: center;
		flex-shrink: 2;
		gap: 10px;
	}

	.write {
		display: flex;
		flex-shrink: 0.6;
	}

	.wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
	}

	.wrapText {
		gap: 10px;
	}

	.imgMobile {
		display: inline;
		width: 150px;
		height: 141px;
	}

	.img {
		display: none;
	}

	.title {
		font-weight: 800;
		font-size: 19px;
		line-height: 28px;
	}

	.text {
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
	}
}