@import "src/styles/variables";
@import  "src/styles/globals.scss";

.header {
    margin-top: 80px;
    margin-bottom: 48px;
    @media (max-width: $tablet) {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}
.items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 48px;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
    }
}
.body {
    padding-bottom: 48px;
    margin-bottom: 48px;
    border-bottom: 1px solid #D7D7D7;

    @media (max-width: $tablet) {
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
}
.bl_title {
    margin-bottom: 26px;
}
.title {
    font-size: 36px;
    font-weight: 700;
    line-height: 111%;
}
.section_title {
    margin-bottom: 48px;
    @media (max-width: $tablet) {
        margin-bottom: 24px;
    }
}
.bl_img {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 48px;

    &::after {
        content: '';
        padding-bottom: 47%;
        display: block;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.content {
    h1,h2,h3,h4,h5,h6 {
        margin-bottom: 20px;
    }
    p {
		padding-bottom: 20px;
	}
}