@import "../../styles/root.scss";
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  left: 0;
  text-align: center;
  color: white;
  width: 100%;
  gap: 40px;
  background: #ffffff;
  border-bottom: 1px solid #d7d7d7;
  align-items: center;
  padding: 80px 0px;
}

.title {
  color: #111928;
  font-family: "Manrope";
  font-weight: 800;
  font-size: 36px;
  line-height: 60px;
  .subtitle{
    color: #1C7C54;
  }
 
}
.description{
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #5e6166;
  text-align: justify;
}
.img {
  width: 528px;
  height: 640px;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
  border-radius: 16px;
}

.rigth {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}
.subdesc {
  display: flex;
  color: $primary;
  font-size: 16px;
  width: 100%;
  line-height: 36px;
  font-weight: 600;
  text-align: justify;
}


.card {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
  // .container {
  //   flex-direction: column;
  // }

  .img {
    width: 412px;
    height: 555px;
  }
  .subdesc {
    margin-top: 20px;
    font-size: 13px;
    line-height: 20px;
  }
 
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
  .container {
    flex-direction: row;
    gap: 16px;
  }

  .title {
    color: #111928;
    font-family: "Manrope";
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    .subtitle{
      color: #1C7C54;
    }
   
  }
  .description{
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #5e6166;
    text-align: justify;
  }

  .img {
    width: 256px;
    height: 510px;
    overflow: hidden;
  }
  .subdesc {
    margin-top: 20px;
    font-size: 13px;
    line-height: 20px;
  }
 
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .img {
    width: 328px;
    height: 376px;
  }
  .subdesc {
    margin-top: 20px;
    font-size: 13px;
    line-height: 20px;
  }
 
}
