@import "./../../../../styles/variables";
@import "./../../../../styles/colors";

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: $tablet) {
		flex-direction: column;
		align-items: start;
	}
}

.title {
	grid-gap: 0px;

	@media (max-width: $tablet) {

		h2 {
			font-size: 24px;
		}

		margin-bottom: 36px;
	}
}

.buttons {
	display: flex;
	flex-wrap: nowrap;
	width: max-content;
	border-radius: 8px;
	border: 1px solid $primary;

	@media (max-width: $tablet) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;

		.btn {
			width: 100%;
		}
	}
}


.btn_2 {
	background-color: $primary;
	border-radius: 6px;

	color: $white;
}