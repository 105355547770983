@import "../../../../styles/variables.scss";

.wrapper {
	display: flex;
	flex-direction: column;
	grid-gap: 76px;
	margin-top: 80px;

	@media (max-width: $tablet) {
		margin-top: 60px;
		grid-gap: 40px;
	}

	@media (max-width: $tabletSmall) {
		margin-top: 40px;
	}
}