@import "../../../../styles/variables.scss";
@import "../../../../styles/colors.scss";


.img {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	object-fit: contain;
	width: 100%;
	height: 100%;
	border-radius: 16px;

	@media (max-width: $tablet) {
		border-radius: 8px;
	}
}

.iframe {
	width: 100%;
	height: 100%;
	border-radius: 16px;

	.img {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 16px;

		@media (max-width: $tablet) {
			border-radius: 8px;
		}
	}

}