@import '../../../styles/root';

.root {
 
  transition: 100ms linear;
  
  align-items: center;
  cursor: pointer;
}

.contained {
  padding: 12px 24px; 
  background: $primary;
  color: $white;
  border-radius: 8px;
}
.contained:hover{
  background: $primary;
  opacity: 0.7;
}
.contained:active{
  background: $primary;
  
}
.outLine{
  background-color: transparent;
  border: 1px solid #1C7C54;
  color:  $primary;
  border-radius: 8px;
  padding: 12px 24px;
}


.text {
  background: none;
  border-color: transparent;
  color: $white;
  &:hover{
    background: $white;
  }
  &:active{
    background: $white;
  }
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
  &.contained  {
    background: $gray;
    color: $gray;
    border-color: $gray;
    path {
      color: $gray;
    }
  }
  &.outlined {
    background: transparent;
    color: $primary;
    border-color:$primary;
    path {
      color: $primary;
    }
  }
  &.text {
    background: transparent;
    color: $primary;
    border-color: transparent;
    path {
      color: $primary;
    }
  }
}


.content {
  display: flex;
  justify-content: center;
  font-family: 'Manrope';
font-style: normal;
font-weight: 800;
font-size: 13px;
line-height: 20px;
/* identical to box height, or 154% */

display: flex;
align-items: center;

/* Day/Text & Icons/text-01 (inverse) */


border-radius: 4px;
}
.small {
  padding: 12px 128px; 
}


.middle {
  padding: 16px 37px; 
}

.BanerRegistration {
  padding: 12px 25px; 
}

.tiny {
  padding: 12px 24px; 
}

@media only screen and (min-width: 720px) and (max-width: 1024px) {
 
  .contained {
    padding: 10px 13px;
    border-radius: 8px;
  }
  
  .outLine{
    padding: 10px 13px;
    border-radius: 8px;
  }
  
}

@media only screen and (min-width: 320px) and (max-width: 720px) {
  .contained {
    padding: 12px 20px;
    border-radius: 8px;
  }
  
  .outLine{
    padding: 12px 20px;
    border-radius: 8px;
  }
}
// .clean_icon {
//   .before_icon, .after_icon {
//     margin: 0;
//   }
// }
