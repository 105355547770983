@import "../../../styles/variables";
@import "../../../styles/colors";

.items {
	display: flex;
	padding: 0;
	margin: 0;
}

.item {
	a {
		font-size: 16px;
		font-weight: 125%;
		font-weight: 600;
		color: $primary;
	}
}

.item:not(:last-child) {
	position: relative;
	padding-right: 11px;

	a {
		color: #263238
	}

	&::after {
		content: '';
		position: absolute;
		right: 2px;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: #263238;
		width: 3px;
		height: 3px;
		border-radius: 50%;
	}

}