@import "./../../../../styles/variables";
@import "./../../../../styles/colors";
@import "./../../../../styles/globals";

.item {
	max-height: 291px;

	@media (max-width: $tablet) {
		// height: auto;
	}
}

.item_body {
	display: flex;
	flex-direction: column;
	padding: 0px 20px;
	position: relative;
	height: 100%;


	&::after {
		content: '';
		position: absolute;
		left: 0px;
		top: 0;
		width: 1px;
		height: 100%;
		background-color: $primary;
	}

	&::before {
		content: '';
		position: absolute;
		right: 0px;
		top: 0;
		width: 1px;
		height: 100%;
		background-color: $primary;
	}

	@media (max-width: $tablet) {
		padding: 0px 17px;
	}
}

.img_body {
	display: block;
	position: relative;
	width: 100%;
	margin-bottom: 12px;
	border-radius: 16px;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		padding-bottom: 67%;
	}
}

.img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	object-fit: fill;
}

.title {
	font-size: 13px;
	font-weight: 400;
	line-height: 153%;
	margin-bottom: 12px;
	@include lineclamp(3);

	@media (max-width: $tablet) {
		font-size: 15px;
		line-height: 160%;
	}
}

.text {
	font-size: 13px;
	font-weight: 400;
	line-height: 153%;

	@media (max-width: $tablet) {
		font-size: 12px;
		line-height: 166%;
	}
}

.link {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}