@import 'src/styles/root.scss';

.root { 
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.title{
  font-family: 'Manrope'; 
  color: #010101;
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 164%;
  /* or 109% */
  text-align: left;
  font-feature-settings: 'liga' off;
}
.subtitle{
  color: $primary;
}

.desc{
  font-size: 17px;
  color: $black;
  width: 100%;
  line-height: 36px;
  color: #5E6166;
  text-align: left;
  font-weight: 500;
  text-align: justify;
}
.subdesc{
  margin-top: 40px;
  color: $primary;
  font-size: 17px;
  width: 100%;
  line-height: 36px;
  font-weight: 600;
  text-align: left;
}



@media only screen and (min-width: 820px) and (max-width: 1100px) {
  .root { 
    gap: 12px;
  }
  .title {
    font-weight: 800;
    font-size: 33px;
    line-height: 50px;
  }
  .desc {
    font-weight: 500;
font-size: 13px;
line-height: 25px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 824px) {
  .root { 
    gap: 8px;
  }
  .title {
    font-size: 20px;
    line-height: 38px;
  }
  .desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .root { 
    gap: 8px;
  }
  .title {
    font-weight: 800;
font-size: 19px;
line-height: 28px;
  }
  .desc {
    font-weight: 500;
font-size: 12px;
line-height: 20px;
  }
  
}
