.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    animation-name: appear;
    animation-duration: 300ms;
  }
  
  .modalDialog {
    width: 100%;
    max-width: 360px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    background: white;
    position: relative;
    margin: 0 20px;
    max-height: calc(100vh - 40px);
    text-align: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
  }
  
  .modalHeader,
  .modalFooter {
    display: flex;
    align-items: center;
    padding: 1rem;
  }
  .modal-header {
    border-bottom: 1px solid #dbdbdb;
    justify-content: space-between;
  }
  .modalFooter {

    border-top: 1px solid #dbdbdb;
    justify-content: flex-end;
  }
  .modalClose {
    cursor: pointer;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
    font-size: 40px;
    color: #0F1928F2;
  }
  .modalBody {
    overflow: auto;
  }
  .modalContent {
    padding: 1rem;
  }
  
  .modalTitle {
    margin: 0;
  }
  
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes slide-in {
    from {
      transform: translateY(-150px);
    }
    to {
      transform: translateY(0);
    }
  }
  