@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import './variables';
@import './colors';

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  outline: none;
  box-sizing: border-box;
  background: none;
  border: none;
  user-select: auto;
  font-family: 'Manrope';
  font-style: normal;
}
@mixin lineclamp($lines) {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  line-clamp: $lines;
  -webkit-line-clamp: $lines;
  @content;
}

input {
  user-select: auto;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    user-select: text;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  border: 1px solid #D7D7D7;
  border-radius: 8px;
}

a{
  color: #010101;
}

svg {
  pointer-events: none;
}

body,
html {
  overflow-x: hidden;
  background-color: white;
}



.slider-custom-pagination {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	align-items: center;

	// @media (min-width: $tabletSmall) {
	// 	display: flex;
	// }

	&-light {
		.slider-custom-pagination-bullet {
			background: $gray;
		}

		.slider-custom-pagination-bullet-active {
			background: $primary;
		}
	}

	&-dark {
		.slider-custom-pagination-bullet {
			background: $black;
		}

		.slider-custom-pagination-bullet-active {
			background: $primary;
		}
	}
}

.slider-custom-pagination-bullet {
	width: 8px;
	height: 8px;
	display: block;
	border-radius: 50%;
	background: $gray;
	margin: 0 4px;
	cursor: pointer;

	@media (max-width: $tablet) {
		margin: 0 2px;
	}
}

.slider-custom-pagination-bullet-active {
  width: 12px;
  height: 12px;
	background: $primary;
}

