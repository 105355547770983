@import '../../styles/root.scss';

.root {
	overflow: hidden;
	width: 100%;
	display: flex;
	justify-content: center;
	// background-color: #f1f1f1;

	.wrap {
		width: 1104px;
		margin-top: 110px;
		display: flex;
		flex-direction: column;
		background-color: #fff;

		path {
			color: $white;
		}

		gap: 100px;
	}
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
	.root {
		overflow: visible;

		.wrap {
			width: 824px;
			display: flex;
			gap: 80px;
		}
	}
}

@media only screen and (min-width: 600px) and (max-width: 820px) {
	.root {
		overflow: visible;

		.wrap {
			width: 580px;
			display: flex;
			gap: 80px;
		}
	}
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
	.root {
		overflow: visible;

		.wrap {
			width: 328px;
			display: flex;
			gap: 48px;
		}
	}
}