@import "../../styles/root.scss";

.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: center;
	justify-content: space-between;
	width: 100%;
	gap: 48px;
	background: #ffffff;
	// border-bottom: 1px solid #d7d7d7;
	padding: 80px 0px;
	padding-top: 0;
}

.deactivate {
	button:disabled {
		opacity: 0.5;
	}
}

.title {
	font-weight: 800;
	font-size: 44px;
	line-height: 48px;
	color: $black;
}

.button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 30px;
}

.wrapContact {
	display: flex;
	flex-direction: column;
	text-align: left;
	gap: 40px;
}

.mail {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.wrapForm {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

*,
*:focus {
	outline: none;
}

.form {
	display: grid;
	gap: 30px;
	justify-items: flex-start;
}

.formItem {
	position: relative;
	margin-bottom: 15px;
}

.formItem input {
	display: block;
	width: 512px;
	height: 56px;
	background: transparent;
	border: solid 1px #ccc;
	transition: all 0.3s ease;
	padding: 0 15px;
}

.formItem textarea {
	display: block;
	padding: 10px 12px;
	width: 512px;
	background: transparent;
	border-radius: 5px;
	border: solid 1px #ccc;
	transition: all 0.3s ease;
	// padding: 0 15px;
}

textarea {
	display: block;
	width: 500px;
	height: 120px;
}

.formItem input:focus {
	border-color: $primary;
}

.formItem label {
	position: absolute;
	cursor: text;
	z-index: 9999;
	top: -28px;
	left: 0px;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #999;
	transition: all 0.3s ease;
}

.formItem input:focus+label {
	color: $primary;
}

.button {
	display: flex;
	float: left;
}

@media only screen and (min-width: 720px) and (max-width: 820px) {
	.container {
		display: grid;
		grid-template-columns: 1fr;
		text-align: center;
		justify-content: space-between;
		width: 100%;
		gap: 48px;
		background: #ffffff;
		// border-bottom: 1px solid #d7d7d7;
		padding: 20px 0px;
		padding-top: 0;
	}

	.title {
		font-weight: 800;
		font-size: 24px;
		line-height: 28px;
	}

	.formItem input {
		display: block;
		padding: 0px 12px;
		height: 56px;
		padding: 0 15px;
	}

	.formItem textarea {
		padding: 0px 12px;
		padding: 0 15px;
	}
}


@media only screen and (min-width: 320px) and (max-width: 720px) {
	.container {
		display: grid;
		grid-template-columns: 1fr;
		text-align: center;
		justify-content: space-between;
		width: 100%;
		gap: 48px;
		background: #ffffff;
		// border-bottom: 1px solid #d7d7d7;
		padding: 20px 0px;
		padding-top: 0;
	}

	.title {
		font-weight: 800;
		font-size: 24px;
		line-height: 28px;
	}

	.formItem input {
		display: block;
		padding: 0px 12px;
		width: 320px;
		height: 56px;
		padding: 0 15px;
	}

	.formItem textarea {
		padding: 0px 12px;
		width: 320px;
		padding: 0 15px;
	}
}