@import "../../../styles/root.scss";

.root {
  background: $primary;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  margin-top: 160px;
}

.wrap {
  width: 1104px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0px 80px 0px;
}

.horizontal {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.dashed {
  width: 100%;
  border-top: 3px solid #fff;
  border-radius: 5px;
}

.logo {
  width: 127px;
  height: 17px;
}
.link{
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 720px) {
  .root {
    height: auto;
    margin-top: 80px;
  }
  .wrap {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .group {
    flex-direction: column;
    gap: 30px;
  }
  .horizontal {
  font-weight: 800;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 35px;
}
.link{
  font-weight: 800;
font-size: 13px;
line-height: 20px;
}

  .circle {
    background: #00000041;
    border-radius: 50%;
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    stroke: $white;
  }
}
