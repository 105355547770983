@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/globals";


.item_body {
	display: flex;
	flex-direction: column;
	padding: 0px 20px;
	position: relative;
	height: 100%;
	min-height: 379px;


	&::after {
		content: '';
		position: absolute;
		left: 0px;
		top: 0;
		width: 1px;
		height: 100%;
		background-color: $primary;
	}

	&::before {
		content: '';
		position: absolute;
		right: 0px;
		top: 0;
		width: 1px;
		height: 100%;
		background-color: $primary;
	}

	@media (max-width: $tablet) {
		padding: 0px 17px;
		min-height: 280px;
	}
}

.img_body {
	display: block;
	position: relative;
	width: 100%;
	// height: 100%;
	margin-bottom: 12px;
	border-radius: 16px;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		padding-bottom: 67%;
	}
}

.img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	object-fit: fill;
}

.title {
	font-size: 17px;
	font-weight: 700;
	line-height: 164%;
	margin-bottom: 12px;
	@include lineclamp(2);
	@media (max-width: $tablet) {
		font-size: 15px;
		line-height: 160%;
	}
}

.text {
	font-size: 13px;
	font-weight: 400;
	line-height: 153%;
	@include lineclamp(4);

	@media (max-width: $tablet) {
		font-size: 12px;
		line-height: 166%;
	}
}

.link {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}