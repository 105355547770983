.wrapForm {
    margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
*,
*:focus {
  outline: none;
}

.form {
  display: grid;
  gap: 30px;
  justify-items: flex-start;
  margin-bottom: 15px;
}
.formItem {
  position: relative;
  margin-bottom: 15px;
}
.formItem input {
  display: block;
  padding: 0px 12px;
  width: 320px;
  height: 56px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all 0.3s ease;
  padding: 0 15px;
}
.formItem textarea {
  display: block;
  padding: 0px 12px;
  width: 320px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all 0.3s ease;
  padding: 0 15px;
}
textarea {
  display: block;
  width: 500px;
  height: 120px;
}
.formItem input:focus {
  border-color: #1c7c54;
}
.formItem label {
  position: absolute;
  cursor: text;
  z-index: 9999;
  top: -28px;
  left: 0px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #999;
  transition: all 0.3s ease;
}

.formItem input:focus + label {
  color: #1c7c54;
}

.form .button {
  display: flex;
  justify-content: center;
  flex-shrink: inherit;
  padding: 10px 138px 10px 138px;;

}
