@import "../../styles/root.scss";

.container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	text-align: center;
	justify-content: space-between;
	width: 100%;
	gap: 48px;
	background: #ffffff;
	border-bottom: 1px solid #d7d7d7;
	padding: 80px 0px;
}

.text {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 0px;
	gap: 28px;

	width: 336px;
	height: 216px;
}

@media only screen and (min-width: 820px) and (max-width: 1100px) {
	.container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		text-align: center;
		justify-content: space-between;
		gap: 16px;
		background: #ffffff;
		border-bottom: 1px solid #d7d7d7;
		padding: 80px 0px;
	}

	.text {
		padding: 0px;
		gap: 28px;
		align-items: flex-start;
		width: 216px;
		height: 164px;
	}
}

@media only screen and (min-width: 600px) and (max-width: 824px) {
	.container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		text-align: center;
		justify-content: space-between;
		gap: 16px;
		background: #ffffff;
		border-bottom: 1px solid #d7d7d7;
		padding: 80px 0px;
	}

	.text {
		padding: 0px;
		gap: 28px;
		align-items: flex-start;
		width: 156px;
		height: 140px;
	}
}


@media only screen and (min-width: 360px) and (max-width: 600px) {
	.container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		text-align: center;
		justify-content: space-between;
		gap: 16px;
		background: #ffffff;
		border-bottom: 1px solid #d7d7d7;
		padding: 80px 0px;
	}

	.text {
		padding: 0px;
		gap: 28px;
		align-items: flex-start;
		width: 156px;
		height: 140px;
	}
}